import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/Container'
import H1 from '../components/H1'
import H2 from '../components/H2'
import P from '../components/P'
import Button from '../components/Button'

import utils from '../utils'
import media from '../styles/media'

export const query = graphql`
	query WhitepaperQuery($uid: String) {
		prismic {
			allWhitepapers(uid: $uid) {
				edges {
					node {
						...WhitepaperFragment
					}
				}
			}
		}
	}
`

const TitleWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	padding: 3rem 0;
	max-width: 63.187rem;
`
const SubTitleWrapper = styled.div`
	padding-left: 4rem;
	padding-right: 4rem;
`
const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 4.375rem;
	> button {
		z-index: 1;
	}
`

const ImageWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 11rem;
	${media.lessThan('tablet')`
		display: none;
	`};
`

const CornerImage = styled.img`
	position: absolute;
	bottom: 0;
	right: 0;
`

const Hero = ({ title, subtitle, text, button_text, image, trackingLabel }) => {
	const handleOnClick = () => {
		if (typeof window !== `undefined`) {
			const scrollConfig = { behavior: 'smooth' }
			const elmntToScrollTo = window.document.querySelectorAll("section[type='download_whitepaper_form']")[0]
			if (elmntToScrollTo) {
				elmntToScrollTo.scrollIntoView(scrollConfig)
			}
		}
		trackCustomEvent({
			category: 'Whitepaper Download',
			action: 'Click',
			label: trackingLabel
		})
	}

	return (
		<Container type={'hero_with_button'} fullWidth fullSectionWidth isOverflowToTop noPadding backgroundColor={'dark'}>
			<TitleWrapper>
				<H1 variant="primary" color="light">
					{title}
				</H1>
				<SubTitleWrapper>
					<H2 variant="paragraph" color="light">
						{subtitle}
					</H2>
					<P variant="primary" color="light">
						{text}
					</P>
					{button_text && (
						<ButtonWrapper>
							<Button variant="secondary" onClick={handleOnClick}>
								{button_text}
							</Button>
						</ButtonWrapper>
					)}
				</SubTitleWrapper>
			</TitleWrapper>
			{image && (
				<ImageWrapper>
					<CornerImage src={image.url} alt={image.alt} />
				</ImageWrapper>
			)}
		</Container>
	)
}

const Whitepaper = ({ data, location }) => {
	const doc = data.prismic.allWhitepapers.edges.slice(0, 1).pop()

	if (!doc) return null

	const title = doc.node.page_title
	const hero = {
		title: doc.node.hero_title,
		subtitle: doc.node.hero_sub_title,
		text: doc.node.hero_text,
		button_text: doc.node.button_text,
		image: doc.node.hero_image,
		trackingLabel: doc.node.ga_tracking_label
	}
	const body = doc.node.body
	const noIndex = doc.node.no_index

	const Body = (body) => _map(body, (slice, index) => utils.sliceRenderer({ ...slice, key: index, pageTitle: title }))

	return (
		<Layout>
			<SEO
				title={doc.node.meta_title}
				description={doc.node.meta_description}
				keywords={doc.node.meta_keywords}
				image={doc.node.meta_image}
				url={_get(location, 'href')}
				meta={doc.node.meta}
				noIndex={noIndex}
			/>
			<Hero {...hero} />
			<Body {...body} />
		</Layout>
	)
}

export default Whitepaper
